<template>
  <v-container>
    <v-card max-width="700" class="my-10 mx-auto">
      <v-card-title> الأعدادت</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12">
              <v-text-field :rules="rules.name" v-model="sign_data.name">
                <template slot="label">
                  <strong class="red--text">*</strong>
                  الأسم
                </template>
              </v-text-field>
            </v-col>
            <!--/ col -->

            <v-col cols="12" sm="6">
              <v-text-field
                @keypress="email_exist = false"
                :rules="rules.email"
                v-model="sign_data.email"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  البريد الالكتروني
                </template>
              </v-text-field>
            </v-col>
            <!--/ col -->

            <v-col cols="12" sm="6">
              <v-text-field
                @keypress="phone_exist = false"
                :rules="rules.phone"
                v-model="sign_data.phone"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  رقم الهاتف
                </template>
              </v-text-field>
            </v-col>
            <!--/ col -->

            <v-col cols="12" sm="6">
              <v-text-field
                :rules="rules.password"
                type="password"
                v-model="sign_data.password"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  كلمة المرور
                </template>
              </v-text-field>
            </v-col>
            <!--/ col -->

            <v-col cols="12" sm="6">
              <v-text-field
                :rules="rules.c_password"
                type="password"
                v-model="sign_data.c_password"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  تكرار كلمة المرور
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :loading="save_loading"
          :disabled="save_loading"
          @click="sign_up()"
          color="success"
          >حفظ الأعدادت</v-btn
        >
      </v-card-actions>
    </v-card>

    <v-snackbar width="auto" right v-model="snackbar">
      تم تحفظ البيانات بنجاح
      <template v-slot:action="{ attrs }">
        <v-icon color="success" v-bind="attrs">check_circle</v-icon>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "settings",
  data() {
    return {
      snackbar: false,
      dialog: false,
      save_loading: false,
      rules: {
        name: [
          (v) => !!v || "الأسم مطلوب",
          (v) =>
            /^[آ-یA-z]{2,}( [آ-یA-z]{2,})+([آ-یA-z]|[ ]?)$/.test(v) ||
            "كتابة الأسم الاول والثانى على الأقل",
        ],
        email: [
          (v) => !!v || "البريد الالكتروني مطلوب",
          (v) => /.+@.+\..+/.test(v) || "البريد غير صحيح",
          (v) => !this.email_problem || "هذا البريد موجود مسبقا",
        ],
        phone: [
          (v) => !!v || "رقم الهاتف مطلوب",
          (v) => /(01)[0-9]{9}/.test(v) || "رقم الهاتف غير صحيح",
          (v) => !this.phone_problem || "هذا الهاتف موجود مسبقا",
        ],
        password: [
          (v) => !!v || "كلمة المرور مطلوب",
          (v) => !(v.toString().length < 8) || "يجب ان يكون 8 او كثر",
        ],
        c_password: [(v) => !!this.not_mutch || "غير متطابقة"],
        front_img: [(v) => !!v || "الصورة الامامية مطلوبة"],
        back_img: [(v) => !!v || "الصورة الامامية مطلوبة"],
      },
      email_exist: false,
      phone_exist: false,
      sign_data: {
        name: this.$store.state.user_data.name || "",
        email: this.$store.state.user_data.email || "",
        phone: this.$store.state.user_data.phone || "",
        password: "",
        c_password: "",
      },
    };
  },
  computed: {
    email_problem() {
      return this.email_exist;
    },
    phone_problem() {
      return this.phone_exist;
    },
    not_mutch() {
      return this.sign_data.c_password == this.sign_data.password;
    },
  },
  methods: {
    sign_up() {
      if (this.$refs.form.validate()) {
        this.save_loading = true;
        const formData = new FormData();
        Object.keys(this.sign_data).forEach((e, i) => {
          formData.append(e, this.sign_data[e]);
        });
        this.$store
          .dispatch("public__request", {
            config: {
              url: "settings",
              method: "post",
            },
            data: formData,
          })
          .then((res) => {
            // console.log(res);
            this.save_loading = false;
            this.dialog = true;
            this.snackbar = true;
            this.$refs.form.resetValidation();
            localStorage.setItem("user_data", JSON.stringify(this.sign_data));
            setTimeout((e) => {
              this.$router.push("/Dashboard");
            }, 1000);
          })
          .catch((err) => {
            // console.log(err.response);
            this.save_loading = false;
            if (err.response.data.data.email) {
              this.email_exist = true;
            }
            if (err.response.data.data.phone) {
              this.phone_exist = true;
            }
            this.$refs.form.validate();
          });
      }
    },
  },
};
</script>
